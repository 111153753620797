import Vue from "vue";

if (process.client) {

  window.Sentry = {};

  if (typeof window === 'object' && process.env.VUE_APP_MODE != "development") {
    var UA = window.navigator.userAgent.toLowerCase();
    var isIE = UA && /msie|trident/.test(UA);
    if (isIE) {
      if (document.documentMode <= 8) {
        alert("您的IE浏览器版本过低，请升级浏览器");
      }
    } else {
        try {

            var script = document.createElement('script');
            script.src = '//browser.sentry-cdn.com/8.9.2/bundle.min.js';
            script.setAttribute("onload", "loadedSentry()");
            document.head.appendChild(script);

            window.loadedSentry = function() {
              if (Sentry && Sentry.init) {

                  Sentry.init({
                    environment: process.env.VUE_APP_MODE,
                    dsn: "https://e667940002ff8332233f12f597c5a701@o4507088376037376.ingest.de.sentry.io/4507088391962704",

                    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
                    // if your build tool supports it.
                    release: process.env.VUE_APP_SENTRY_RELEASE,
                    integrations: [
                      Sentry.browserTracingIntegration(),
                      Sentry.replayIntegration(),
                    ],

                    // Set tracesSampleRate to 1.0 to capture 100%
                    // of transactions for tracing.
                    // We recommend adjusting this value in production
                    tracesSampleRate: 1.0,

                    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
                    tracePropagationTargets: ["localhost", /^https:\/\/hankahuanbao.sentry\.io\/api/],

                    // Capture Replay for 10% of all sessions,
                    // plus for 100% of sessions with an error
                    replaysSessionSampleRate: 0.1,
                    replaysOnErrorSampleRate: 1.0,
                  });

                  window.addEventListener("error", (event) => {
                    if (event.message) {
                      console.log("页面错误", event);
                      Sentry.captureMessage(event.error);
                    } else {
                      console.log("资源event", event);
                      console.log("资源", event.target);
                      console.log("资源类型", Object.prototype.toString.call(event.target).slice(8, -1));
                      Sentry.captureMessage(event.target);
                    }
                  }, true);

                  //全局错误捕获
                  Vue.config.errorHandler = (error) => {
                    if (Sentry && Sentry.captureException) {
                      console.log("Vue内部错误", error);
                      Sentry.captureException(error);
                    }
                  }

                  //设置用户信息
                  Sentry.setUser({
                    ip_address: "{{auto}}",
                  });

               };
            };
        } catch (err) {
          console.log("Sentry异常", err)
        }
    }
  }
}



//log 蓝色
// setTimeout(()=>{
//   Sentry.captureMessage(`Log: 111`)
// },1000)


//error 橙色
//Sentry.captureException(err)
// Sentry.captureEvent({
//   message:"111",
//   user:"222"
// })
